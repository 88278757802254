<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item>
				<el-input v-model="keywords" placeholder="医生姓名/手机号" clearable></el-input>
			</el-form-item>
			<el-form-item label="互联网诊疗状态：">
				<el-select v-model="serviceStatus" placeholder="请选择" style="width: 80px;" clearable>
					<el-option
						v-for="item in serviceStatuss"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="科室：">
				<el-select v-model="deptId" placeholder="请选择" clearable>
					<el-option
						v-for="item in depts"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="name" header-align="center" align="center" label="名称"></el-table-column>
			<el-table-column prop="titleName" header-align="center" align="center" label="医生职称"></el-table-column>
			<el-table-column prop="mobilePhone" header-align="center" align="center" label="手机号"></el-table-column>
			<el-table-column prop="deptName" header-align="center" align="center" label="科室"></el-table-column>
			<el-table-column prop="hospitalName" header-align="center" align="center" label="医院名称"></el-table-column>
			<el-table-column prop="serviceStatus" header-align="center" align="center" label="互联网诊疗">
				<template slot-scope="scope">
					<el-switch
						v-model="scope.row.serviceStatus"
						@change="changeServiceStatus(scope.row)"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="1"
						:inactive-value="0"
						:active-text="scope.row.serviceStatus === 1 ? '已开启' : '已关闭'"
					>
					</el-switch>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Doctor',
	data () {
		return {
			keywords: '',
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			serviceStatus: '',
			serviceStatuss: [
				{ label: '全部', value: '' },
				{ label: '关闭', value: 0 },
				{ label: '开启', value: 1 }
			],
			deptId: '',
			depts: []
		};
	},
	created () {
		this.search();
		this.getDepts();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('doctorList', {
				keywords: this.keywords,
				serviceStatus: this.serviceStatus,
				deptId: this.deptId,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		getDepts () {
			this.$root.request('deptList', {
				page: 1,
				limit: 1000
			}).then((data) => {
				if (data) {
					this.depts = data.rows.map(r => {
						return { label: r.name, value: r.id };
					});
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		changeServiceStatus (row) {
			this.$root.request('doctorServiceStatusUpdate', {
				doctorId: row.doctorId,
				status: row.serviceStatus
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('修改成功');
					this.getData();
				} else {
					row.serviceStatus ? (row.serviceStatus = 0) : (row.serviceStatus = 1);
				}
			});
		}
	}
};
</script>